@font-face {
  font-family: "Sweet Sans Pro Medium";
  src: url(../assets/fonts/SweetSansProMedium.otf);
  font-display: swap;
}
@font-face {
  font-family: "Sweet Sans Pro Regular";
  src: url(../assets/fonts/SweetSansProMedium.otf);
  font-display: swap;
}

@font-face {
  font-family: "newyork";
  src: url(../assets/fonts/NewYork.otf);
  font-display: swap;
}

@font-face {
  font-family: "newyork";
  src: url(../assets/fonts/NewYork.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Buljirya";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Buljirya"),
    url("../assets/fonts/BuljiryaPersonalUse-Yz06j.woff") format("woff");
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alkatra:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600&family=Elsie&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Open+Sans:wght@400;500;600;700;800&display=swap");
