.right .btn-close {
  width: 21px;
  height: 21px;
  padding: 10px;
  position: relative;
  background-color: transparent;
  border-radius: 50%;
  color: #8d021f;
  position: relative;
  top: auto;
  right: 16px;
  opacity: 1;
  z-index: 99;
  overflow: hidden;
  background-size: 13px;
}
.right {
  z-index: 99999;
}
.fade.right.modal.show {
  padding: 0 !important;
  margin: 0 !important;
}
.modal.fade:not(.show).right .modal-dialog {
  -webkit-transform: translate3d(50%, 0, 0);
  transform: translate3d(50%, 0, 0);
}
.right .modal-dialog {
  float: right;
  max-width: 566px;
  width: 90%;
  width: 100%;
  border: 0;
  padding: 0 !important;
  margin: 0 !important;
  height: 100vh;
}
body {
  padding: 0 !important;
  margin: 0 !important;
}
.right .modal-content {
  border: 0px !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 100vh;

  border-radius: 25px 0 0 25px;
  overflow: hidden;
}
.right .modal-header {
  background-color: #f2f2f2;
}
.right .main-price {
  width: 100%;
}
.right .small-text {
  font-size: 13px;
  line-height: 14px;
  padding-top: 3px;
  width: 100%;
}
.right .large-text {
  font-size: 17px;
  font-weight: 600;
  width: 100%;
  line-height: 20px;
}
.right .modal-header {
  padding: 10px 0 10px 34px;
}
.right .modal-body {
  padding: 0;
  overflow: auto;
  max-height: 100vh;
}
.right .price {
  color: #222;
  font-size: 16px;
  font-weight: 600;
}
.right .cross-price {
  font-size: 14px;
  color: rgb(157, 159, 164);
  text-decoration: line-through;
  padding-left: 10px;
}
.right .modal-footer {
  padding: 0;
  border: 0;
  margin: 0;
}
@media only screen and (min-width: 220px) and (max-width: 767px) {
  .right .modal-dialog {
    transition: transform 0.1s ease-out;
    transform: translate(0, 50px);
  }
  .right .modal-dialog {
    position: fixed !important;
    bottom: 0 !important;
    left: 0% !important;
    right: 0% !important;
    margin-bottom: 0 !important;
  }
  .modal.fade:not(.show).right .modal-dialog {
    transition: transform 0.1s ease-out;
    transform: translate(0, 50px);
  }

  .right .modal-dialog {
    float: none;
    max-width: 566px;
    width: 100%;
    border: 0;
    padding: 0 !important;
    margin: 0 !important;
    height: auto;
    max-height: 100vh;
  }
  .right .modal-content {
    border-radius: 24px 24px 0 0px;
    max-height: 90vh;
  }
  .right .modal-header {
    padding: 10px 0 10px 18px;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 99;
    border-radius: 24px 24px 0 0px;
  }
  .right .modal-body {
    padding: 0;
    overflow: auto;
    padding: 60px 0 50px 0;
    max-height: 100vh;
  }
  .right .modal-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 99;
  }
}
