@keyframes blink-color {
  0% {
    color: red;
  }
  50% {
    color: orange;
  }
  100% {
    color: red;
  }
}
@keyframes blink-color-positive {
  0% {
    color: red;
  }
  50% {
    color: green;
  }
  100% {
    color: red;
  }
}
