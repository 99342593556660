.house-senco {
  .swiper-pagination {
    bottom: 22px !important;
  }
  .swiper-pagination-bullet {
    background-color: transparent !important;
    border: 1px solid #fff;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: #fff !important;
  }
}

.pointer-cursor {
  cursor: pointer;
}
.house-of-senco-mobile-nav {
  .navbar {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .navbar-nav {
    border-top: 1px solid #ddd;
    padding: 0;
    margin: 10px 0 0 0 !important;
  }
  .container-fluid {
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    float: right;
    top: 10px;
    position: relative;
  }
  .navbar-collapse {
    margin: 0 -15px;
    a {
      padding: 9px 15px;
      border-bottom: 1px solid #ddd;
    }
    .nav-item.show.dropdown {
      margin: 0;
      .dropdown-menu {
        border: 0;
        background: #f7f7f7;
        padding: 0;
        padding-top: 7px;
        margin: 0;
        a {
          border: 0;
          padding: 0 25px;
        }
      }
    }
  }
}
