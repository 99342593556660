@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "_variables";
@import "_fonts";

:root {
  --swiper-navigation-color: #ff1933;
  --swiper-theme-color: #ff1933;
  --toastify-z-index {
    z-index: 9999999999999999999999999 !important;
  }
}

fw-storyblock {
  --fw-storyblock-background: transparent;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h6 {
  font-size: 60px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lato", sans-serif;
  color: #444444;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

/*Top Header*/
ul,
li {
  margin: 0px;
  padding: 0px;
}

/*top header end*/
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
}
.swiper-pagination-bullet {
  background-color: #ff1933 !important;
}
.swiper-pagination-bullet-active {
  background-color: #ff1933 !important;
}

/*banner*/

#mobile-main-header {
  display: none;
}

.color2 {
  color: #ff1933;
}

.color_green {
  color: #28b521;
}

.container {
  position: relative;
}

.slick-prev {
  background-image: url(../assets/images/prv.webp) !important;
  width: 44px !important;
  height: 78px !important;
  left: -50px !important;
  &::before {
    display: none !important;
  }
}
.slick-next {
  background-image: url(../assets/images/next.webp) !important;
  width: 44px !important;
  height: 78px !important;
  right: -50px !important;
  &::before {
    display: none !important;
  }
}
.d_none_mobile {
  display: none !important;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .d_none_mobile {
    display: none !important;
  }
}
header .container {
  position: inherit !important;
}

header {
  .container {
    position: inherit !important;
  }
}
.position-inherit {
  position: inherit !important;
}
.clearfix {
  clear: both;
}
.min-h {
  min-height: auto !important;
}
.btn-close {
  width: 30px;
  height: 30px;
  padding: 10px;
  position: relative;
  background-color: #fff;
  border-radius: 50%;
  color: #8d021f;
  position: absolute;
  top: -4%;
  right: -2%;
  opacity: 1;
  z-index: 99;
  &:hover {
    opacity: 1 !important;
  }
}
.page-header {
  width: 100%;
  position: relative;
  font-size: 24px;
  background: #f5f5f5;
  margin: 7px 0;
  z-index: 9;
  padding: 10px;
  color: #ff1933;
  text-align: center;
}
.pointer {
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
// .fw-dropdown::after {
//   background-color: #ff1933;
//   position: absolute;
//   left: 0;
//   width: 100%;
//   content: "";
//   top: 0;
//   height: 0px;
// }
.banner_main {
  width: 100%;
  padding-bottom: 20px;
  .swiper-slide {
    div {
      position: relative;
      width: 100% !important;
      clear: both;
    }
  }
}
.banner_main .swiper-dotted span.swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px) !important;
}

.banner_main .swiper-dotted span.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 8px)
  ) !important;
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 8px)
  ) !important;
}
.banner_main .swiper-slide {
  display: block;
}
.btn-outline-info {
  color: #ff1d25;
  border-color: #ff1d25;
  &:hover {
    background-color: #ff1d25;
    border-color: #ff1d25;
    color: #fff;
  }
}
.icon-color i {
  color: #ff1d25;
}
.input-filter-check {
  margin-top: 2px;
}
@media only screen and (min-width: 220px) and (max-width: 767px) {
  .max-w {
    margin: 3.5px 0px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    height: 25px !important;
    overflow: hidden;
  }
}
.breadcrumb-item a {
  text-transform: capitalize;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
}
.breadcrumb-item.active {
  color: #ff1933;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  margin: 0px;
  padding: 0px;
  display: table-cell;
  line-height: 25px;
}
$comment-img-size: 3rem;
$comment-img-size-sm: 1.75rem;

.comment {
  &-img {
    width: $comment-img-size;
    height: $comment-img-size;
  }

  &-replies &-img {
    width: $comment-img-size-sm;
    height: $comment-img-size-sm;
  }
}
.comment-btn {
  background-color: #ff1933 !important;
  color: #fff !important;
  border: 1px solid #ff1933 !important;
  &:hover,
  &:focus {
    color: #ff1933 !important;
    background-color: transparent !important;
    border: 1px solid #ff1933 !important;
  }
}
.cancel {
  color: #ff1933 !important;
  text-decoration: none;
}
.min-h-100 {
  min-height: 120px !important;
}
.text_red {
  color: #ff1933;
}
.best-sellers-d .swiper {
  padding-bottom: 10px;
}
.best-sellers-d .swiper-pagination-bullet {
  border: 1px solid #ff1933;
}
.best-sellers-d .swiper-horizontal > .swiper-pagination-bullets,
.best-sellers-d .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -6px !important;
}
.fast_red {
  color: #ff1933;
  font-weight: 600;
}
.fast_input {
  border: 1px solid #ff1933;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: flex-start !important;
}

.launcher-wrapper {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  box-sizing: border-box;
  height: 76px;
}
.notification-message-bottomRight {
  display: flex;
}
.closed-widget-bottomRight {
  align-items: flex-end;
  bottom: 30px;
  right: 35px;
  z-index: 9999;
}
.notification-message-bottomRight .last-message-notification {
  position: relative;
  overflow: hidden;
  max-width: 240px;
  background: #fff;
  border-radius: 0.625rem;
  line-height: 1.35;
  cursor: pointer;
  text-align: start;
  max-height: 72px;
  padding: 14px 20px;
  z-index: 999999;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 40%);
  margin: 0 10px;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  height: 100%;
}
.iconContainer {
  position: relative;
}
.ellipsis p {
  margin-bottom: 0px;
}
.unread-count-bottomLeft,
.unread-count-bottomRight {
  top: -10px;
  position: absolute;
  z-index: 99999999;
  color: #fff;
  background: #f44336;
  height: 20px;
  width: 20px;
  border: 2px solid #fff;
  text-align: center;
  border-radius: 15px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}
.unread-count-bottomRight {
  right: 0;
}
.round-icon-container {
  border-radius: 50%;
  height: 60px;
  width: 60px;
}
.launcher-icon-bottomRight .avatar {
  background-size: cover;
  background-position: 50%;
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  border-radius: 0.3125rem;
  cursor: pointer;
  background-image: url("/img/Chatbot.webp");
  box-shadow: 0 2px 6px 0 rgb(32 0 0 / 40%);
}
.roundIcon {
  height: 60px !important;
  width: 60px !important;
  border-radius: 50% !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  .ellipsis {
    word-break: break-word;
  }
  .closed-widget-bottomRight {
    align-items: flex-start;
    bottom: 10px;
    right: auto;
    left: 20px;
    z-index: 999;
  }
}
.p-relative {
  position: relative;
}
.top-text-topbar {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  padding-bottom: 3px;
}
.pagination .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ff1933;
  border-color: #ff1933;
}
.pagination .page-link {
  position: relative;
  display: block;
  color: #ff1933;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ff1933;
}
.pagination .page-item.disabled .page-link {
  color: #ffa0ab;
  pointer-events: none;
  background-color: #fff;
  border-color: #f19ea7;
}
.top_new {
  position: absolute;
  top: -14px;
  right: -8px;
  width: 25px !important;
  height: 17px !important;
  span {
    position: relative !important;
    width: 25px !important;
    height: 17px !important;
    img {
      width: 25px !important;
      height: 17px !important;
    }
  }
}
.display-fl {
  display: flex;
  justify-content: space-between;
}
.max-w {
  max-width: 110px;
  span {
    width: auto !important;
  }
}

.horizontal-range-slider {
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 50px;
}

.horizontal-range-slider .range-slider-track {
  position: absolute;
  left: 0px;
  right: 450px;
  top: 20px;
  height: 5px;
}

.range-slider-track.range-slider-track-1 {
  background: #ff7888;
}
.range-slider-track.range-slider-track-0,
.range-slider-track.range-slider-track-2 {
  background: #fcc3ca;
}

.horizontal-range-slider .range-slider-thumb {
  top: 14px;
  width: 18px;
  height: 16px;
  line-height: 38px;
  background: #ff1933;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.react-slider__ul li.active img {
  border-color: #ff1933 !important;
}
.react-slider__areaZoom img {
  border: none !important;
}
.react-slider__ul {
  margin-top: 71px !important;
}

.user_accordian {
  width: 100%;
  list-style: none;
  margin: 0px !important;

  background: #f5f5f5;

  li {
    border-top: 1px solid #ddd;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 30px !important;
    a {
      h1 {
        font-weight: 600 !important;
      }
    }
  }
}

.pb-and-at-offer_main {
  width: 100%;
  position: relative;
  .container-fluid {
    max-width: 85%;
  }
  .height100 {
    height: 100%;
  }
  .pb-and-at-offer_container {
    width: 100%;
    position: relative;
    a {
      width: 100%;
      display: block;
      position: relative;
      span {
        width: 100% !important;
      }
      img {
        width: 100% !important;
      }
    }
  }
  .pb-and-at-offer_sec {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    h2 {
      color: #ff1933;
      font-family: "newyork";
      font-weight: 900;
    }
    .custom-box {
      padding: 40px 30px 40px 0;
      ul {
        li {
          font-family: "Lato", sans-serif;
          padding: 10px 0;
          list-style: disc;
          margin-left: 10px;
        }
      }
    }
  }
  .form_center {
    padding-left: 40px;
    font-size: 16px;
    margin: 50px 0;
    label {
      color: #fff !important;
    }
    // background: rgb(255 255 255 / 95%);
    .form-control {
      display: block;
      width: 100%;
      padding: 12px 15px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      color: #000;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ff1933;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 0.375rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    button {
      background: #ff1933;
      border: 1px solid #ff1933;
      font-size: 17px;
      width: 150px;
      /* margin: 0 auto; */
      display: block;
      font-weight: 600;
    }
    ::placeholder {
      color: #fd7327;
      font-weight: 500;
      font-size: 16px;
    }

    ::-webkit-input-placeholder {
      color: #fd7327;
      font-weight: 500;
      font-size: 16px;
    }

    ::-moz-placeholder {
      color: #fd7327;
      font-weight: 500;
      font-size: 16px;
    }

    :-ms-input-placeholder {
      color: #fd7327;
      font-weight: 500;
      font-size: 16px;
    }

    ::-ms-input-placeholder {
      color: #fd7327;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .top-text-topbar {
    font-size: 13px;
    padding-bottom: 3px;
  }
  .pb-and-at-offer_sec {
    position: relative !important;
    background-image: url(../assets/images/bgpb.jpg);
    background-size: 100% 100%;
    background-color: #590d10;
    margin-top: -7px;
    .form_center {
      padding: 0;
      margin: 25px 0;
    }
  }

  .offer_container {
    width: 100%;
    position: absolute !important;
    top: 0;
  }
  .pb-and-at-offer_main {
    width: 100%;
    position: relative;
  }
  .pb-and-at-offer_main .pb-and-at-offer_sec .custom-box {
    padding: 0 !important;
  }
  .pb-and-at-offer_main .pb-and-at-offer_container {
    width: 100%;
    position: relative !important;
  }
}
.bg__offers {
  a {
    color: #ff1933;
  }
  padding: 50px 0;
  h2 {
    color: #ff1933;
    font-family: "newyork";
    font-weight: 900;
  }
}
.top-bg {
  background-image: url("https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/p9USCogofcz4QZ2AZbC1fGGJIHDTe6Ii4mSjxMgu.png");
  max-width: 980px;
  margin: 0 auto;
  display: block;
  height: 100px;
  width: 100%;
  background-size: 100%;
}
.bottom-t {
  background-image: url("https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/ephxZkF3JmKSdWslR2hjWk99y0encogFPd3rE2Hx.png");
  max-width: 980px;
  margin: 0 auto;
  display: block;
  height: 100px;
  width: 100%;
  background-size: 100%;
}
.mid-sec {
  background-image: url("https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/FV8EyypzZcPUGRe66GjfKIpPohbis25TcobKYzf9.png");
  max-width: 946px;
  margin: 0 auto;
  display: block;
  width: 100%;
  background-size: 100%;
}

.top-bg1 {
  background-image: url("https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/RqFZwySxhnvs2ghEwH2SyOZjuvQyHhU9XjYN6shm.png");
  max-width: 980px;
  margin: 0 auto;
  display: block;
  height: 100px;
  width: 100%;
  background-size: 100%;
}
.bottom-t1 {
  background-image: url("https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/sohRe706heAJxhNOFJPgC5iFXT2qkPXaX3YoBAqx.png");
  max-width: 980px;
  margin: 0 auto;
  display: block;
  height: 88px;
  width: 100%;
  background-size: 100%;
}
.mid-sec1 {
  background-image: url("https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/5oYf3unvrQyGy47iBZM5YdEhcMq1ePchVFmgonVT.png");
  max-width: 934px;
  margin: -17px auto;
  display: block;
  width: 100%;
  background-size: 100%;
}

.mid-sec a {
  background-color: #92693d;
  color: #fff4b1;
  text-align: center;
  display: block;
  margin: 10px auto;
  font-size: 20px;
  max-width: 347px;
  width: 100%;
}
.dot-st {
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 0px;
  position: relative;
}
.dot-st li {
  position: relative;
  font-size: 18px;
  color: #464645;
  padding: 5px 30px 15px 40px;

  list-style: none;
  font-weight: 400;
}
.dot-st li::before {
  position: absolute;
  left: 20px;
  top: 13px;
  content: "";
  width: 7px;
  height: 7px;
  background-color: #ff1933;
  border-radius: 50%;
}

@media only screen and (min-width: 980px) and (max-width: 1024px) {
  .top-bg {
    height: 72px;
    background-repeat: no-repeat;
  }
  .mid-sec a {
    font-size: 16px;
  }
  .mid-sec1 {
    width: 95.3%;
  }
  .bottom-t {
    height: 101px;
    background-repeat: no-repeat;
  }
  .top-bg1,
  .bottom-t1 {
    height: 78px;
    background-repeat: no-repeat;
  }
  .mid-sec1 {
    width: 95.1%;
  }
  .dot-st li {
    font-size: 16px;
  }
  .mid-sec {
    width: 96.6%;
    padding: 0px 15px;
    font-size: 16px;
  }
  .mid-sec p {
    margin: 0px !important;
  }
  .custom-control label {
    font-size: 19px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
  .top-bg {
    height: 75px;
    background-repeat: no-repeat;
  }
  .mid-sec a {
    font-size: 16px;
  }
  .bottom-t {
    height: 75px;
    background-repeat: no-repeat;
  }
  .top-bg1,
  .bottom-t1 {
    height: 75px;
    background-repeat: no-repeat;
  }
  .mid-sec1 {
    width: 95.3%;
  }
  .dot-st li {
    font-size: 16px;
  }
  .mid-sec {
    width: 96.6%;
    padding: 0px 15px;
    font-size: 16px;
  }
  .mid-sec p {
    margin: 0px !important;
  }
  .custom-control label {
    font-size: 19px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-and-at-offer_main .form_center {
    margin: 0 !important;
  }
  .pb-and-at-offer_main .form_center .form-control {
    display: block;
    width: 100%;
    padding: 5px 15px !important;
  }
  .pb-and-at-offer_main .form_center {
    padding-left: 15px !important;
    font-size: 15px;
  }
}
.mobile-img {
  display: none !important;
}
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .desktop-img {
    display: none !important;
  }
  .mobile-img {
    display: block !important;
    a {
      width: 100% !important;
      span {
        width: 100%;
        img {
          width: 100% !important;
        }
      }
    }
  }
  .top-bg {
    height: 48px;
    background-repeat: no-repeat;
  }
  .mid-sec a {
    font-size: 16px;
  }
  .bottom-t {
    height: 50px;
    background-repeat: no-repeat;
  }
  .top-bg1,
  .bottom-t1 {
    height: 49px;
    background-repeat: no-repeat;
  }
  .mid-sec1 {
    width: 95%;
  }
  .dot-st li {
    font-size: 16px;
  }
  .mid-sec {
    width: 97%;
    padding: 0px 15px;
    font-size: 16px;
  }
  .mid-sec p {
    margin: 0px !important;
  }
  .custom-control label {
    font-size: 19px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 639px) {
  .top-bg {
    height: 48px;
    background-repeat: no-repeat;
  }
  .mid-sec a {
    font-size: 16px;
  }
  .bottom-t {
    height: 50px;
    background-repeat: no-repeat;
  }
  .top-bg1,
  .bottom-t1 {
    height: 49px;
    background-repeat: no-repeat;
  }
  .mid-sec1 {
    width: 95%;
  }
  .dot-st li {
    font-size: 16px;
  }
  .mid-sec {
    width: 97%;
    padding: 0px 15px;
    font-size: 16px;
  }
  .mid-sec p {
    margin: 0px !important;
  }
  .custom-control label {
    font-size: 19px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .top-bg {
    height: 34px;
    background-repeat: no-repeat;
  }
  .mobile-img {
    display: block !important;
  }
  .desktop-img {
    display: none !important;
  }
  .mid-sec a {
    font-size: 16px;
  }
  .bottom-t {
    height: 34px;
    background-repeat: no-repeat;
  }
  .top-bg1,
  .bottom-t1 {
    height: 34px;
    background-repeat: no-repeat;
  }
  .mid-sec1 {
    width: 95%;
  }
  .dot-st li {
    font-size: 16px;
  }
  .mid-sec {
    width: 97%;
    padding: 0px 15px;
    font-size: 16px;
  }
  .mid-sec p {
    margin: 0px !important;
  }
  .custom-control label {
    font-size: 19px;
  }
}
.banner_mg_offer {
  width: 100%;
  position: relative;
  height: 300px;
  span {
    width: 100% !important;
    img {
      width: 100% !important;
    }
  }
}
.mobile_offer_banner {
  display: none;
}
.link_page_offer {
  width: 100%;
  height: 100px;
  position: relative;
}
@media only screen and (min-width: 1601px) and (max-width: 2000px) {
  .banner_mg_offer {
    height: 400px !important;
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1600px) {
  .banner_mg_offer {
    height: 350px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_mg_offer {
    height: 231px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_mg_offer {
    height: 184px !important;
  }
}
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .banner_mg_offer {
    height: 150px !important;
  }
  .desktop_offer_banner {
    display: none !important;
  }
  .mobile_offer_banner {
    display: block !important;
    height: 670px !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 639px) {
  .banner_mg_offer {
    height: 100px !important;
  }
  .desktop_offer_banner {
    display: none !important;
  }
  .mobile_offer_banner {
    display: block !important;
    height: 465px !important;
  }
}
@media only screen and (min-width: 220px) and (max-width: 479px) {
  .banner_mg_offer {
    height: 90px !important;
  }
  .desktop_offer_banner {
    display: none !important;
  }
  .mobile_offer_banner {
    display: block !important;
    height: 382px !important;
  }
}
.custom_modal_offers {
  svg {
    position: absolute;
    left: 0;
    border: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  .custom_modal_offers_container {
    margin: 15px;
    border: 1px dashed #d9232d;
    .modal-header {
      justify-content: center;
      padding: 10px 0px;
      color: #bc0000;
      border: 0;
      position: relative;
      background-color: #fff;
    }
    .modal-body {
      position: relative;
      background-color: #fff;
      padding: 0 20px 20px 20px;
    }
    .form-control {
      display: block;
      width: 100%;
      padding: 7px 10px;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fbfbfb;
      background-clip: padding-box;
      border: 1px solid #b5b5b5;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 5px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .button1 {
      background: linear-gradient(90deg, #ff1934 0%, #ea00b8 100%);
      color: #fff;
      /* margin-right: 0.9rem; */
      text-align: center;
      padding: 5px 10px;
      font-size: 20px;
      border-radius: 3px;
      outline: 0;
      border: 1px solid #ff1934;
      font-family: "Lato", sans-serif;
      width: 150px;
      line-height: 31px;
    }
  }
  .btn-close {
    width: 30px;
    height: 30px;
    padding: 10px;
    position: relative;
    background-color: #fff;
    border-radius: 50%;
    color: #8d021f;
    position: absolute;
    top: -30px;
    right: -30px;
    opacity: 1;
    z-index: 99;
  }
}

.st0 {
  stroke-dasharray: 2050 1000 40 80 1500 1000 2050;
  animation: dash 30s infinite linear;
}

@keyframes dash {
  to {
    stroke-dashoffset: 4000;
  }
}
.st0 {
  fill: none;
  stroke: #ff1933;
  stroke-width: 1;
  stroke-miterlimit: 10;
  stroke-dasharray: 60, 100, 300, 120, 220, 24;
}
@media only screen and (min-width: 220px) and (max-width: 767px) {
  .btn-close {
    right: 2px !important;
    top: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .link_page_offer {
    width: 100%;
    height: 80px;
    position: relative;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .link_page_offer {
    width: 100%;
    height: 61px;
    position: relative;
  }
}
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .link_page_offer {
    width: 100%;
    height: 90px;
    position: relative;
  }
}
@media only screen and (min-width: 480px) and (max-width: 639px) {
  .link_page_offer {
    width: 100%;
    height: 80px;
    position: relative;
  }
}
@media only screen and (min-width: 381px) and (max-width: 479px) {
  .link_page_offer {
    width: 100%;
    height: 68px;
    position: relative;
  }
}
@media only screen and (min-width: 220px) and (max-width: 380px) {
  .link_page_offer {
    width: 100%;
    height: 60px;
    position: relative;
  }
}

.d-none-desktop-view {
  display: none;
}
@media only screen and (min-width: 220px) and (max-width: 767px) {
  .d-none-mobile-view {
    display: none;
  }
  .d-none-desktop-view {
    display: block;
  }
}
.justify-content-space-around {
  justify-content: space-between;
}
.custom_border_0 {
  border: 0 !important;
}
.justify-content-space-between {
  justify-content: space-between;
}

.offer_mg_page {
  width: 100%;
  // background-image: url("../assets/images/bgsen.jpg");
  background-size: 100%;
  position: relative;
  img {
    width: 100%;
  }
  .form_container {
    label {
      font-family: "Philosopher", sans-serif;
      color: #111;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .top-bottom-left-right {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
  }
  .text-header {
    padding-top: 90px;
  }
  .custom-tandc {
    display: flex;
    justify-content: center;
    .form-check-label {
      color: #111;
      font-family: "Alkatra", cursive;
      font-weight: 600;
      font-size: 18px;
    }
  }
  .button1 {
    width: 250px;
    height: 50px;
    background: transparent;
    border: 0;
    padding: 0;
  }
  .form-control {
    border-radius: 0;
    color: #222;
    font-family: "Philosopher", sans-serif;
    font-weight: 600;
    font-size: 16px;
    height: 40px;
  }
  ::placeholder {
    color: #222;
    font-family: "Philosopher", sans-serif;
    font-weight: 600;
    font-size: 16px;
  }

  ::-webkit-input-placeholder {
    color: #222;
    font-family: "Philosopher", sans-serif;
    font-weight: 600;
    font-size: 16px;
  }

  ::-moz-placeholder {
    color: #222;
    font-family: "Philosopher", sans-serif;
    font-weight: 600;
    font-size: 16px;
  }

  :-ms-input-placeholder {
    color: #222;
    font-family: "Philosopher", sans-serif;
    font-weight: 600;
    font-size: 16px;
  }

  ::-ms-input-placeholder {
    color: #222;
    font-family: "Philosopher", sans-serif;
    font-weight: 600;
    font-size: 16px;
  }
}
.mobile-bg-off {
  display: none !important;
}

.custom-tand-c-signop .form-check-input {
  border: 1px solid rgb(210 34 43);
}
.custom-tand-c-signop .form-check-input:checked {
  background-color: rgb(210 34 43);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offer_mg_page .form-control {
    height: 30px !important;
  }
  .offer_mg_page .button1 {
    width: 150px;
    height: 30px;
    img {
      width: 100% !important;
      height: 30px !important;
    }
  }
  .offer_mg_page .text-header {
    padding-top: 35px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offer_mg_page .form-control {
    height: 30px !important;
  }
  .offer_mg_page .button1 {
    width: 150px;
    height: 30px;
    img {
      width: 100% !important;
      height: 30px !important;
    }
  }
  .offer_mg_page .text-header {
    padding-top: 35px !important;
    span {
      width: 260px !important;
      height: 100px !important;
      margin: 0 auto !important;
    }
    img {
      margin: 0 auto !important;
      width: 260px !important;
      height: 100px !important;
    }
  }
}
@media only screen and (min-width: 220px) and (max-width: 767px) {
  .try_container {
    display: none;
  }
  .landing__pop {
    max-width: 400px;
    .desktop-landing-pop-offer {
      display: none !important;
    }
    .mobile-landing-pop-offer {
      display: block !important;
    }
  }
  .desktop-bg-off {
    display: none !important;
  }
  .mobile-bg-off {
    display: block !important;
  }
  .submit {
    width: 100%;
    font-size: 18px;
    line-height: 46px;
    color: #fff;
    background-color: #b06006;
    font-family: "Alkatra", cursive;
    text-transform: uppercase;
    opacity: 1;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .offer_mg_page .text-header {
    padding-top: 30px;
  }
  .offer_mg_page .custom-tandc .form-check-label {
    color: #fff;
    a {
      color: #fff !important;
    }
  }
  .offer_mg_page {
    .form_container {
      label {
        color: #fff;
      }
    }
  }
}
.product_slider_zoom_slide .react-slider__ul li.active img {
  position: relative !important;
}

.animation-text {
  -webkit-animation: colorchange 20s infinite alternate;
}
@-webkit-keyframes colorchange {
  0% {
    color: #ff1933;
  }

  25% {
    color: #d4af37;
  }

  50% {
    color: #ff1933;
  }
  75% {
    color: #d4af37;
  }
  100% {
    color: #ff1933;
  }
}

.justify-content-space-around {
  justify-content: space-between;
}
.custom_border_0 {
  border: 0 !important;
}
.main_gift_full {
  background-color: #f6f4f5;
  padding: 30px 0;
  h1 {
    font-size: 25px;
    font-weight: 600;
    color: #ff1933;
  }
  h3 {
    font-size: 20px;
    font-size: 600;
  }
  .red-color {
    color: #ff1933;
  }
}
.gift_card_main {
  width: 100%;
  position: relative;
  padding: 40px 0;
  h5 {
    color: #ff1932;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
  }
  .card_box {
    // border: 1px solid #ddd;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1), 0 0px 0px rgba(0, 0, 0, 0.22);
    .slider-img {
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1), 0 0px 0px rgba(0, 0, 0, 0.22);
      margin: 10px 0;
      border: 2px solid #f2f2f2;
      cursor: pointer;
      span {
        height: auto !important;
      }
      &:hover {
        border: 2px solid #ff1934;
        box-shadow: 0 5px 16px rgb(255 25 52 / 13%),
          0 0px 0px rgb(255 25 52 / 13%);
      }
    }
    .active {
      border: 2px solid #ff1934;
      box-shadow: 0 5px 16px rgb(255 25 52 / 13%),
        0 0px 0px rgb(255 25 52 / 13%);
    }
    .custom-value {
      max-width: 100px;
    }
    // label {
    //   margin-bottom: 5px;
    // }

    .custom-select {
      display: inline-block;
      width: 100%;
      height: calc(2.25rem + 2px);
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      line-height: 1.5;
      color: #495057;
      vertical-align: middle;
      border: 1px solid rgba(253, 25, 50, 0.4);
      background: rgb(253 25 50 / 2%)
        url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
        no-repeat right 0.75rem center;
      background-size: auto;
      background-size: 8px 10px;
      font-size: 16px;
      border-radius: 0.25rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    h4 {
      color: #ff1932;
      font-size: 18px;
      font-weight: 500;
      padding: 10px;
      font-family: "Sweet Sans Pro Medium";
      background-color: #f5f5f5;
    }
    .buyers_info {
      width: 100%;
      .form-group {
        margin-bottom: 10px;
      }
      input {
        border-radius: 3px;
        padding: 6px 10px;
        border: 1px solid #ddd;
      }
    }
    .card_header {
      width: 100%;
      position: relative;
      background-color: #d51110;
      color: #fff;
      padding: 10px 20px;
      font-size: 18px;
      display: block;
      font-family: "Sweet Sans Pro Regular";
      aside {
        float: right;
        width: 36px;
        height: 36px;
        background: #fff;
        color: #d51110;
        border-radius: 50%;
        font-weight: 600;
        line-height: 34px;
        margin-top: -4px;
        text-align: center;
      }
    }
    .card_body {
      padding: 15px;
      .image_text {
        display: flex;
        color: #ff1d25;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 200px;
        border-radius: 5px;
        border: 1px solid #ddd;
        font-size: 22px;
        margin-top: 15px;
      }
      .gift_form {
        width: 100%;

        input,
        textarea {
          border-radius: 3px;
          padding: 6px 10px;
          background-color: rgba(253, 25, 50, 0.02);
          border: 1px solid rgba(253, 25, 50, 0.4);
          font-size: 16px;
        }
      }
    }
  }
  .add-to-gift {
    background-color: #d51110;
    padding: 7px 25px;
    color: #fff;
    display: inline-block;
    margin-top: 15px;
    font-size: 16px;
    border-radius: 3px;
    font-weight: 500;
    margin: 0 auto;
    margin-bottom: 10px;
    max-width: 230px;
    width: 100%;
    border: 1px solid #d51110;
    font-family: "Sweet Sans Pro Regular";
  }
  .copy-to-gift {
    background-color: #d51110;
    padding: 7px 15px;
    color: #fff;
    display: inline-block;
    margin-top: 15px;
    font-size: 18px;
    border-radius: 3px;
    margin-left: 15px;
    font-weight: 500;
    border: 1px solid #d51110;
  }
  .form-check-input:checked {
    background-color: #fe344a;
    border-color: #fe344a;
  }
  .form-control {
    background-color: rgb(253 25 50 / 2%);
    border: 1px solid rgba(253, 25, 50, 0.4);
  }
}

.modal-head-preview {
  color: #ff1932;
  font-size: 20px;
  font-family: "Sweet Sans Pro Medium";
}
.custom_preview_gift {
  .modal-footer {
    margin-top: -6px;
    z-index: 9;
  }
}

.modal_pop_prev {
  background-color: #fff;
  padding: 0px;
  position: relative;
  span {
    width: 100%;
    height: 100% !important;
  }
  .overtop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
  }
  .birth-card {
    max-width: 200px;
    margin: 10px auto;
  }
  .gift_image {
    max-width: 100%;
    margin: 0 auto;
  }
  aside {
    width: 100%;
  }
  h6 {
    font-size: 23px;
    color: #fe344a;
    font-family: "newyork";
    text-align: center;
    font-weight: 700;
  }
  h4 {
    font-style: italic;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
  }
  p {
    font-size: 17px;

    max-width: 480px;
    margin: 0 auto;
    font-weight: 600;
    font-style: italic;
    color: #222;
    text-align: center;
  }
  .sender_info {
    float: right;
    padding-right: 40px;
    h4 {
      font-size: 23px;
      color: #fe344a;
      font-family: "newyork";
      text-align: left;
      font-weight: 600;
    }
    h5 {
      font-size: 23px;
      color: #fe344a;
      font-family: "newyork";
      text-align: left;
      font-weight: 600;
    }
    p {
      font-size: 16px;
      font-family: "Sweet Sans Pro Regular";
      max-width: 480px;
      margin: 0 auto;
      font-weight: 400;
      color: #222;
      text-align: left;
    }
  }
}
.modal_pop_prev {
  padding: 0px;
  aside {
    width: 100%;
  }
  .form-check-input:checked {
    background-color: #fe344a;
    border-color: #fe344a;
  }
  .form-control {
    background-color: rgba(253, 25, 50, 0.02);
    border: 1px solid rgba(253, 25, 50, 0.4);
  }
  .slider-img.active {
    border: 2px solid #ff1932;
    padding: 5px;
  }
  .card_box {
    border: 0px solid #ddd;
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 0px;
    label {
      margin-bottom: 5px;
    }
    .custom-select {
      display: inline-block;
      width: 100%;
      height: calc(2.25rem + 2px);
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      line-height: 1.5;
      color: #495057;
      vertical-align: middle;

      background: rgba(253, 25, 50, 0.04)
        url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
        no-repeat right 0.75rem center;
      background-size: auto;
      background-size: 8px 10px;
      border: 1px solid rgba(253, 25, 50, 0.4);
      border-radius: 0.25rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    h4 {
      color: #ff1932;
      font-size: 18px;
      font-weight: 500;
      padding: 10px;
      font-family: "Sweet Sans Pro Medium";
      background-color: #f5f5f5;
    }
    .buyers_info {
      width: 100%;
      .form-group {
        margin-bottom: 10px;
      }
      input {
        border-radius: 3px;
        padding: 6px 10px;
        border: 1px solid #ddd;
      }
    }
    .card_header {
      width: 100%;
      position: relative;
      background-color: #d51110;
      color: #fff;
      padding: 10px 20px;
      font-size: 18px;
      display: block;
      font-family: "Sweet Sans Pro Regular";
      aside {
        float: right;
        width: 36px;
        height: 36px;
        background: #fff;
        color: #d51110;
        border-radius: 50%;
        font-weight: 600;
        line-height: 34px;
        margin-top: -4px;
        text-align: center;
      }
    }
    .card_body {
      padding: 15px;
      .image_text {
        display: flex;
        color: #ff1d25;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 200px;
        border-radius: 5px;
        border: 1px solid #ddd;
        font-size: 22px;
        margin-top: 15px;
      }
      .gift_form {
        width: 100%;

        input,
        textarea {
          border-radius: 3px;
          padding: 6px 10px;
          background-color: rgba(253, 25, 50, 0.02);
          border: 1px solid rgba(253, 25, 50, 0.4);
        }
      }
    }
  }
  .add-to-gift {
    background-color: #d51110;
    padding: 7px 25px;
    color: #fff;
    display: inline-block;
    margin-top: 15px;
    font-size: 16px;
    border-radius: 3px;
    font-weight: 500;
    margin: 0 auto;
    max-width: 270px;
    border: 1px solid #d51110;
    font-family: "Sweet Sans Pro Regular";
  }
}
.close-modal-footer {
  background-color: #d51110;
  padding: 7px 25px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  border-radius: 3px;
  font-weight: 500;
  max-width: 270px;
  border: 1px solid #d51110;
  font-family: "Sweet Sans Pro Regular";
}
.custom_preview_gift .btn-close {
  width: 30px;
  height: 30px;
  padding: 10px;
  position: relative;
  background-color: #fff;
  border: 1px solid #222;
  border-radius: 50%;
  color: #8d021f;
  position: absolute;
  top: -15px;
  right: -8px;
  opacity: 1;
  z-index: 99;
}
.mobile-view-gift {
  display: none !important;
}

.main_gold_sec {
  z-index: 99;
  padding: 15px;
  border: 1px dashed #ff1933;
  position: relative;
  .heading_modal {
    font-size: 18px;
    font-weight: 600;
    padding-top: 0px;
    text-align: center;
    color: #ff1933;
    padding-bottom: 10px;
  }
  .form-control {
    background-color: rgb(251 251 251 / 77%);
  }
  .button_act {
    background: transparent;
    border: 1px solid #222;
    padding: 5px 20px;
    display: inline-block;
    color: #222;
    font-size: 16px;
    margin-bottom: 7px;
    border-radius: 5px;
    &:hover {
      background-color: #959595;
      border: 1px dashed #959595;
      color: #fff;
    }
    &.active {
      background-color: #959595;
      border: 1px dashed #959595;
      color: #fff;
    }
  }
  .button_act_sub {
    background: linear-gradient(90deg, #ff1934, #ea00b8);
    border: 1px dashed transparent;
    color: #fff;
    padding: 5px 20px;
    display: inline-block;
    border-radius: 5px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gift_card_main .add-to-gift {
    font-size: 14px;
  }

  .custom_preview_gift .modal-dialog {
    max-width: 715px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .mobile-view-gift {
    display: block !important;
  }
  // .desktop-view-gift {
  //   display: none !important;
  // }
  .desktop-view-gift {
    height: 260px !important;
  }
  .custom_preview_gift .modal-footer {
    margin-top: 0;
  }
  .desktop-view-gift img {
    height: 260px !important;
  }
  .birth-card img {
    width: 70px !important;
  }
  .birth-card {
    width: 70px !important;
    margin: 0 auto !important;
  }
  .modal_pop_prev h4,
  .modal_pop_prev .h4,
  .modal_pop_prev .sender_info h5,
  .modal_pop_prev .sender_info .h5 {
    font-style: normal;
    font-size: 14px;
    margin: 0;
  }
  .modal_pop_prev .sender_info p {
    font-size: 14px;
    padding: 0;
  }
  .modal_pop_prev h6,
  .modal_pop_prev .h6 {
    font-size: 15px;
    font-style: normal;
  }
  .modal_pop_prev p {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    padding: 0 15px;
  }
  .custom_preview_gift .btn-close {
    width: 20px;
    height: 20px;
    padding: 10px;
    position: relative;
    background-color: #fff;
    border: 1px solid #222;
    border-radius: 50%;
    color: #8d021f;
    position: absolute;
    top: 0px;
    right: -8px;
    opacity: 1;
    z-index: 99;
  }
  .gift_card_main .add-to-gift {
    width: 100%;
  }
  .gift_card_main .card_box .card_header aside {
    float: right;
    width: 36px;
    height: 31px;
    background: #fff;
    color: #d51110;
    border-radius: 50%;
    font-weight: 600;
    line-height: 29px;
    margin-top: 0px;
    text-align: center;
  }
  .gift_card_main .card_box .card_header {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
.customer_consent_main {
  width: 100%;
  background-color: #fff;
  padding: 25px;
  .heading {
    font-size: 23px;
    font-family: "Philosopher", sans-serif;
    font-weight: 600;
    text-align: center;
    width: 100%;
    display: block;
    margin-bottom: 10px;
    color: #222;
    line-height: 25px;
  }
  .logo_pop {
    max-width: 200px;
    width: 100%;
    margin: 0 auto 10px auto;
    display: block;
  }
  .border-pop {
    border: 1px dashed #ff1934;
    padding: 25px;
  }
  .custom-fill {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .red_button {
    background-color: #ff1934;
    padding: 6px 10px;
    color: #fff;
    margin-left: 20px;

    border: 1px dashed #ff1934;
  }
  p {
    font-size: 18px;
    font-family: "Philosopher", sans-serif;
    margin: 0;
    padding-top: 10px;
  }
  .customer_consent_list {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 18px;
    li {
      list-style: decimal;
      color: #222;
      width: 100%;
      margin: 0;
      padding: 5px 0;
      font-size: 18px;
      font-family: "Philosopher", sans-serif;
      &::marker {
        font-weight: 600;
      }
      a {
        color: #ff1934;
        font-size: 18px;
        font-family: "Philosopher", sans-serif;
      }
      .link {
        word-break: break-all;
      }
    }
  }
}
@media (min-width: 220px) and (max-width: 640px) {
  .customer_consent_main {
    padding: 10px;
    .border-pop {
      padding: 10px;
    }
  }
}
.h_100 {
  height: 100%;
}
.bg_transparent {
  background-color: transparent;
}
.border-right-0 {
  border-right: 0;
}
.border-left-0 {
  border-left: 0;
}
.check .form-check-input:checked {
  background-color: #ff1933;
  border-color: #ff1933;
}

.astro_dash_card {
  border: 1px solid #ddd;
  position: relative;
  width: 100%;
  margin: 20px 0px;
  .astro_dash_card_body {
    position: relative;
    width: 100%;
    padding: 0px;
    margin: 0px 0px;

    .astro_dash_card_body_head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      padding: 10px;
      .order_no {
        border: 1px solid #ff1933;
        color: #ff1933;
        padding: 7px 15px;
        display: inline-block;
      }
      .delivered {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
      }
    }
    .lab-600 {
      font-weight: 600;
      color: #ff1932;
    }
    .main_body {
      padding: 15px !important;
      .min-w-100 {
        min-width: 55px;
      }

      .button {
        background-color: #ff1933;
        color: #fff;
        border: 1px solid #ff1933;
        padding: 7px 20px;
      }
    }
    .astro_dash_footer {
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #ddd;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 639px) {
  .astro_dash_card .astro_dash_card_body .astro_dash_card_body_head {
    width: 100%;
    display: block !important;
    justify-content: center !important;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
}

input#timeofbirth {
  display: inline-block;
  position: relative;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.Toastify .Toastify__toast-container {
  z-index: 999999999999 !important;
}
#mirrar-webar,
#mirrar-popup,
.mirrar-popup {
  z-index: 99999 !important;
}
